import { ErrorBoundary, StandaloneConfiguration } from '@amzn/sitc-frontend/components';
import { backlotLightTheme } from '@amzn/sitc-frontend-theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import { microAppName } from './configs/app';

const StudiosFrontendUtility = React.lazy(() => import('./components/StudiosFrontendUtility'));

const configuration = { appId: 'studios-portal-app' };
const MicroAppComponent = StudiosFrontendUtility;
const rootComponent = () => (
  <StandaloneConfiguration
    RootComponent={MicroAppComponent}
    configuration={configuration}
    microAppName={microAppName}
    standaloneModeFeatures={{ microAppUserPool: true }}
  />
);

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent,
  errorBoundary: (error: Error, info: React.ErrorInfo) => (
    <ThemeProvider theme={backlotLightTheme}>
      <CssBaseline />
      <ErrorBoundary error={error} info={info} />
    </ThemeProvider>
  ),
});

export const { bootstrap, mount, unmount } = lifecycles;
